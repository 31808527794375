<template>
  <v-btn class="nav-link" variant="text" @click="openDialog">
    <v-icon icon="mdi mdi-basket" size="18" />
    <template #append>
      <span v-if="cart.totalItems.value > 0" class="quantity">{{
        cart.totalItems
      }}</span>
    </template>
  </v-btn>

  <v-dialog v-model="dialog.show" v-bind="dialog.bind">
    <v-card>
      <v-card-title>{{ $t("default.shopping_cart.title") }}</v-card-title>
      <v-card-text>
        <div v-if="cart.items.value.length">
          <ul class="px-0">
            <li v-for="(item, i) in cart.items.value" :key="i">
              <strong class="h3 text-white">{{ item.name }}</strong>
              <div class="flex align-center gap-4">
                <v-number-input
                  v-model="item.quantity"
                  :reverse="false"
                  control-variant="split"
                  :hide-input="false"
                  hide-details
                  inset
                  min="1"
                  class="max-w-[150px]"
                />
                <v-btn
                  icon="mdi mdi-trash-can-outline"
                  @click="cart.removeItem(item)"
                />
                <div class="ml-auto">
                  {{ item.singlePrice }} |
                  {{ cart.itemTotalPrice(item) }}
                </div>
                <!-- <span class="currency">zzgl. MwSt.</span> -->
              </div>
              <v-divider class="mb-3" />
            </li>
            <li>
              <div class="flex align-center gap-4">
                <strong class="h3 text-white">Gesamt</strong>
                <strong class="ml-auto">{{ cart.totalPrice }}</strong>
              </div>
            </li>
          </ul>
          <button color="primary" class="button ml-auto" @click="cart.checkout">
            {{ $t("default.checkout.btn.label") }}
          </button>
        </div>
        <div v-else>
          {{ $t("default.shopping_cart.empty.text") }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { VNumberInput } from "vuetify/labs/components";

const dialog = reactive({
  show: false,
  bind: {
    width: 600,
  },
});

const cart = useShoppingCart();

function openDialog() {
  dialog.show = true;
}
</script>

<style scoped lang="scss">
.currency {
  font-size: 12px;
}
</style>
