<script setup>
import "@mdi/font/css/materialdesignicons.min.css";

// const darkMode = useDarkLightSwitch()
const darkMode = true;
</script>

<template>
  <Header />
  <main
    :class="{
      'theme-light': !darkMode,
      'theme-dark': darkMode,
    }"
  >
    <slot />
  </main>
  <Footer />
</template>

<style lang="scss">
html.v-overlay-scroll-blocked #__nuxt {
  filter: blur(5px);
  height: 100%;
  width: 100%;
}

ul li {
  text-align: left;
}
h3 {
  color: var(--1-main-colors--white);
}
.pre-h2 {
  font-weight: bold;
  font-family: Montserrat, sans-serif;
  color: rgb(255, 255, 255) !important;
}
p {
  text-wrap: balance;
}
.gap-6 {
  gap: 36px;
}
.gap-8 {
  gap: 48px;
}
.gap-10 {
  gap: 60px;
}
button,
.w-tab-link,
.clickable {
  cursor: pointer;
  transition: color 0.35s;
}
.white-logo {
  filter: invert(1) brightness(0) invert(1);
}
.fade-image {
  position: relative;
  overflow: hidden;
}

.fade-image img {
  display: block;
  width: 100%;
  height: auto;
}

.fade-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  height: 100%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 80%);
  /* Hintergrundfarbe auf Schwarz einstellen für Schwarz-Übergang */
}
.feature-bento-box.clickable,
.follow-box {
  transition: all 0.4s;

  &:hover {
    box-shadow: 0 0 0 7px var(--2-other--link-bg-grey);
    color: #fff;
    background-color: #000;

    .follow-box-icon-wrap,
    .clickable-hover-effect {
      opacity: 1;

      &.hover-effect-soft {
        opacity: 1;
      }
    }
  }

  .follow-box-icon-wrap,
  .clickable-hover-effect {
    opacity: 0.6;
    transition: all 0.4s;

    &.hover-effect-soft {
      opacity: 0.85;
    }
  }
}

.sticky-right {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}

.accordions {
  .v-expansion-panels {
    grid-column-gap: 2em;
    grid-row-gap: 1em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    display: flex;
  }
  .v-expansion-panels--variant-accordion > :first-child:not(:last-child),
  .v-expansion-panels--variant-accordion > :not(:first-child):not(:last-child),
  .v-expansion-panels--variant-accordion > :last-child:not(:first-child) {
    border-radius: 12px !important;
  }

  .v-expansion-panel {
    grid-column-gap: 0em;
    grid-row-gap: 0em;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 12px !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    transition: box-shadow 0.4s;
    display: flex;
    border: 1px solid rgba(255, 255, 255, 0.08);
    transition: all 0.3s;
    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.15);
    }
  }
  .v-expansion-panel-title:not(.v-expansion-panel-title--active) {
    color: var(--1-main-colors--white);

    @media screen and (min-width: 991px) {
      font-size: 20px;
    }
  }
}

.v-expansion-panel {
  .v-expansion-panel-title {
    font-size: 16px;
    background-color: rgba(255, 255, 255, 0);
    overflow: hidden;
  }
  .v-expansion-panel-title--active {
    background-color: var(--1-main-colors--white);

    &,
    h3 {
      color: var(--1-main-colors--black);
    }
  }

  &.v-expansion-panel--active {
  }

  border-bottom: 1px solid var(--2-other--light-grey);
  color: var(--1-main-colors--text-color);
}

.v-card {
  box-shadow: 0 0 0 7px var(--2-other--light-grey) !important;
}
.clickable-card {
  overflow: hidden;

  &.active,
  &:hover:not(.active) {
    opacity: 1;
  }

  .v-card-text {
    max-width: 720px;
    margin: 0 auto;
  }

  .v-img__img--cover {
    opacity: 0.7;
    transition: all 0.8s ease-in-out;
  }

  &:hover:not(.active) {
    .v-img__img--cover {
      opacity: 1;
      transform: scale(1.05) !important;
    }
  }
}
.v-overlay__scrim {
  background-color: #000;
  opacity: 0.5;
}

@media screen and (min-width: 601px) {
  .pre-h2 {
    font-size: larger;
  }
  .card-wrapper .flex-wrap .text-wrapper {
    padding-right: 2em;
  }
}
@media screen and (min-width: 991px) {
  .esg-definition h2 {
    font-size: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .lg\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}

.academy-price {
  font-size: 22px;
  font-weight: 700;
}
.academy-currency {
  font-size: 12px;
}
</style>
